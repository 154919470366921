import Typography from "typography"
import altonTheme from "typography-theme-alton"

altonTheme.overrideThemeStyles = () => ({
  a: {
    color: "var(--textLink)",
  },

  "a:hover": {
    color: "var(--textLink)",
  },
  // gatsby-remark-autolink-headers - don't underline when hidden
  "a.anchor": {
    boxShadow: "none",
  },

  blockquote: {
    color: "var(--textNormal)",
    borderLeft: "5px solid rgb(var(--textLink))",
  },

  td: {
    color: "var(--textNormal)",
    borderBottom: "1px solid var(--hr)",
  },

  th: {
    color: "var(--textNormal)",
    borderBottom: "1px solid var(--hr)",
  },

  // gatsby-remark-autolink-headers - use theme colours for the link icon
  'a.anchor svg[aria-hidden="true"]': {
    stroke: "var(--textLink)",
  },
  hr: {
    background: "var(--hr)",
  },
})

altonTheme.bodyFontFamily = ["Lora", "PT Serif", "serif"]
altonTheme.headerFontFamily = ["Lora", "PT Serif", "serif"]
altonTheme.baseFontSize = "16px"
altonTheme.headerWeight = 700
altonTheme.bodyWeight = 300

const typography = new Typography(altonTheme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
