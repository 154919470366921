import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"
import ThemeChanger from "../components/switchTheme"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath || location.pathname === "/about/") {
    header = (
      <div>
        <ThemeChanger />
        <h1
          style={{
            ...scale(0.4),
            fontFamily: "Lora, PT Serif, serif",
            marginBottom: rhythm(0.1),
            marginTop: 0,
            color: `rgb(var(--textTitle))`,
          }}
        >
          {title}
        </h1>
      </div>
    )
  } else {
    header = (
      <div>
        <ThemeChanger />
        <h3
          style={{
            marginTop: 0,
            marginBottom: rhythm(2),
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              color: `inherit`,
              textDecoration: `none`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      </div>
    )
  }
  return (
    <div
      style={{
        margin: `0 auto`,
        maxWidth: `45rem`,
        padding: `2.5rem 1.25rem`,
        backgroundColor: `var(--bg)`,
        color: `var(--textNormal)`,
        transition: `color 0.2s ease-out`,
      }}
    >
      <header>{header}</header>
      <main>{children}</main>
      <footer style={{ textAlign: "center", paddingTop: "2rem" }}>
        Built with
        {` `}
        <a href="https://www.gatsbyjs.com">GatsbyJS</a>
      </footer>
    </div>
  )
}

export default Layout
