import React from "react"
import { ThemeToggler } from "gatsby-plugin-dark-mode"

class ThemeChanger extends React.Component {
  render() {
    return (
      <div id="toggler">
        <ThemeToggler>
          {({ theme, toggleTheme }) => {
            if (theme == null) {
              return "dark"
            }
            return (
              <label className="toggle-control">
                <input
                  type="checkbox"
                  className="slider round"
                  onChange={e =>
                    toggleTheme(e.target.checked ? "dark" : "light")
                  }
                  checked={theme === "dark"}
                />
                <span className="control"></span>
              </label>
            )
          }}
        </ThemeToggler>
      </div>
    )
  }
}

export default ThemeChanger
